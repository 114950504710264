<template>
  <div>
    <div class="container">
      <h1 class="text-start p-3 m-3"><b>研究テーマ</b></h1>
      <section>
        <div class="row g-3">
          <div class="col-lg-6">
            <div class="border rounded-9 p-3 bg-light">
              <div class="row">
                <div class="col-md-8">
                  <router-link to="/research/hub"><h5>Swarm Robots(HUB)</h5></router-link>
                  <p>複数のロボットを自律的に動かす研究</p>
                  <ul class="text-start">
                    <li>確率的探査</li>
                    <li>マーカシステムによるロボットの制御</li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <div
                    class="bg-image hover-overlay shadow-1-strong rounded ripple"
                    data-mdb-ripple-color="light"
                  >
                    <img src="@/assets/img/HUB/KlabHub.jpg" class="img-fluid" />
                    <a href="#!">
                      <div
                        class="mask"
                        style="background-color: rgba(251, 251, 251, 0.15)"
                      ></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="border rounded-9 p-3 bg-light">
              <div class="row">
                <div class="col-md-8">
                  <router-link to="/research/rover"><h5>Rover</h5></router-link>

                  <p>ローバが安全に目的地まで走行できるような自立機能に関する研究</p>
                  <ul class="text-start">
                    <li>軌道補正</li>
                    <li>自律経路計画</li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <div
                    class="bg-image hover-overlay shadow-1-strong rounded ripple"
                    data-mdb-ripple-color="light"
                  >
                    <img src="@/assets/img/rover/KlabRover.jpg" class="img-fluid" />
                    <a href="#!">
                      <div
                        class="mask"
                        style="background-color: rgba(251, 251, 251, 0.15)"
                      ></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="border rounded-9 p-3 bg-light">
              <div class="row">
                <div class="col-md-8">
                  <h5>Drone</h5>
                  <p>ドローンによる探査・行動・経路計画に関する研究</p>
                  <ul class="text-start">
                    <li>短距離飛翔</li>
                    <li>自律経路計画・確率的探査</li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <div
                    class="bg-image hover-overlay shadow-1-strong rounded ripple"
                    data-mdb-ripple-color="light"
                  >
                    <img src="@/assets/img/drone/KlabDrone.jpg" class="img-fluid" />
                    <a href="#!">
                      <div
                        class="mask"
                        style="background-color: rgba(251, 251, 251, 0.15)"
                      ></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="border rounded-9 p-3 bg-light">
              <div class="row">
                <div class="col-md-8">
                  <h5>Hems</h5>
                  <p>AIとセンサ技術による生活適応型省エネの実現に関する研究</p>
                  <ul class="text-start">
                    <li>音声認識</li>
                    <li>デバイスコントローラの制作</li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <div
                    class="bg-image hover-overlay shadow-1-strong rounded ripple"
                    data-mdb-ripple-color="light"
                  >
                    <img src="@/assets/img/hems/KlabHems.png" class="img-fluid" />
                    <a href="#!">
                      <div
                        class="mask"
                        style="background-color: rgba(251, 251, 251, 0.15)"
                      ></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
