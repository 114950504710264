<template>
  <header>
    <transition appear @before-enter="headerBeforeEnter" @enter="headerEnter">
      <nav class="navbar navbar-expand-lg fixed-top navbar-scroll">
        <transition appear @before-enter="linksBeforeEnter" @enter="linksEnter">
          <div class="container">
            <router-link to="/" class="nav-link" aria-current="page">
              <img
                src="@/assets/img/logo/klab.png"
                height="70"
                alt="國井研究室ロゴ"
                loading="lazy"
              />
            </router-link>

            <button
              class="navbar-toggler ps-0"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#navbarExample01"
              aria-controls="navbarExample01"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="visible = !visible"
            >
              <!-- class ="navbar-toggler-icon"が機能しないので画像を貼り付け -->
              <span
                class="navbar-toggler-icon d-flex justify-content-start align-items-center"
              >
                <span class=""
                  ><img
                    src="@/assets/img/logo/toggle_icon.png"
                    alt="toggler icon"
                    height="35"
                    width="35"
                /></span>
              </span>
            </button>
            <div
              class="navbar-collapse"
              :class="!visible ? 'collapse' : ''"
              id="navbarExample01"
            >
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item active">
                  <router-link
                    to="/"
                    class="nav-link"
                    aria-current="page"
                    @click="visible = !visible"
                  >
                    Home
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/member"
                    class="nav-link"
                    aria-current="page"
                    @click="visible = !visible"
                  >
                    Member
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/research"
                    class="nav-link"
                    aria-current="page"
                    @click="visible = !visible"
                  >
                    Research
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/link"
                    class="nav-link"
                    aria-current="page"
                    @click="visible = !visible"
                  >
                    Link
                  </router-link>
                </li>
              </ul>

              <ul class="navbar-nav flex-row">
                <li class="nav-item">
                  <a class="nav-link px-2" href="#!">
                    <i class="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link px-2" href="#!">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link ps-2" href="#!">
                    <i class="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </nav>
    </transition>
  </header>
</template>

<style>
.navbar-scroll .nav-link,
.navbar-scroll .fa-bars,
.navbar-scroll .navar-brand {
  color: #4f4f4f;
}

.navbar-scroll .nav-link:hover {
  color: #1266f1;
}

/* Color of the links AFTER scroll */
.navbar-scrolled .nav-link,
.navbar-scrolled .fa-bars,
.navbar-scrolled .navar-brand {
  color: #4f4f4f;
}

/* Color of the navbar AFTER scroll */
.navbar-scroll,
.navbar-scrolled {
  background-color: #fff;
}

/* An optional height of the navbar AFTER scroll */
.navbar.navbar-scroll.navbar-scrolled {
  padding-top: 5px;
  padding-bottom: 5px;
}

header {
  margin-bottom: 100px;
}
</style>

<script>
import gsap from "gsap";
export default {
  data() {
    return {
      visible: false,
    };
  },
  setup() {
    // ヘッダーが上から下がってくるアニメーション
    const headerBeforeEnter = (el) => {
      gsap.set(el, {
        y: "-100%",
        opacity: 0,
      });
    };

    const headerEnter = (el, done) => {
      gsap.to(el, {
        opacity: 1,
        duration: 1,
        y: "0",
        ease: "Power0.easeOut",
        onComplete: done,
      });
    };

    // リンクがフェイドインするアニメーション
    const linksBeforeEnter = (el) => {
      el.style.opacity = 0;
    };

    const linksEnter = (el, done) => {
      gsap.to(el, {
        duration: 1,
        opacity: 1,
        delay: 1.5,
        onComplete: done,
      });
    };
    return {
      headerBeforeEnter,
      linksBeforeEnter,
      headerEnter,
      linksEnter,
    };
  },
};
</script>
