<template>
  <div>
    <h3 class="text-start m-3 ">Access</h3>
    <p class="text-muted text-start m-3">
      〒112-8551 東京都文京区春日1-13-27 <br />
      TEL 03-3817-1711 <br>
      <ul>
        <li>東京メトロ丸の内線・南北線「後楽園駅」より徒歩５分</li>
        <li>都営三田線・大江戸線「春日駅」より徒歩6分</li>
        <li>JR中央・総武線「水道橋駅」より徒歩12分 , 「飯田橋駅」より徒歩17分</li>
        </ul>
    </p>
    <div
      id="map-container-google-1"
      class="z-depth-1-half map-container m-1"
      style="height: 500px"
    >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1926.3613242608535!2d139.74768496894842!3d35.70799892321525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c488cd531ab%3A0x8fbea8043716d87e!2z5Lit5aSu5aSn5a2mIOW-jOalveWckuOCreODo-ODs-ODkeOCuQ!5e0!3m2!1sja!2sjp!4v1648636591578!5m2!1sja!2sjp"
        width="600"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>

<style >
.map-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>