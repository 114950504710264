<template>
  <div>
    <h3 class="text-start p-3 m-3">{{ year }}年度</h3>
    <ul class="text-start m-3">
      <li>修士 :{{ master }}</li>
      <li>学士 : {{ bachelor }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    year: Number,
    master: String,
    bachelor: String,
  },
};
</script>
