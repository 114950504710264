<template>
  <div>
    <footer
      class="text-white position-absolute bottom-0 vw-100 mt-3"
      style="background-color: #aaa"
    >
      <div
        class="text-center p-3"
        style="background-color: rgba(0, 0, 0, 0.01)"
      >
        Copyright &copy; 2022 Kunii Laboratory.<br />
        All Rights Reserved.
      </div>
    </footer>
  </div>
</template>

<style>
footer {
  margin-bottom: -80px;
}
</style>