<template>
  <!-- カードをトリガーとしたモーダル -->
  <div class="card" data-bs-toggle="modal" :data-bs-target="target">
    <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
      <img
        :src="img_src"
        class="mt-1 rounded img-fluid"
        :height="img_height"
        :width="img_width"
      />
      <a href="#!">
        <div class="mask" style="background-color: rgba(251, 251, 251, 0.15)"></div>
      </a>
      <p class="card-text m-1">{{ name }}</p>

      <!-- モーダルの設定 -->
      <div
        class="modal fade"
        :id="modal_id"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ name }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="閉じる"
              ></button>
            </div>
            <div class="modal-body text-start">
              <div class="row g-3">
                <div class="col-lg-3">
                  <img
                    :src="img_src"
                    :alt="name"
                    :height="img_height"
                    :width="img_width"
                    class="mx-auto d-block img-fluid rounded"
                  />
                </div>
                <div class="col-lg-9">
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    img_src: String,
    img_height: Number,
    img_width: Number,
    target: String,
    modal_id: String,
  },
};
</script>

<style scoped>
small {
  font-size: 10;
}
</style>
