<template>
  <div class="mt-3">
    <p class="text-start">
      <small class="text-start m-3">{{ date }}</small>
    </p>
    <h6 class="text-start m-3">
      <a :href="href" target="_blank" rel="noopener">{{ content }}</a>
    </h6>
    <hr />
  </div>
</template>

<script>
export default {
  props: {
    date: Number,
    content: String,
    href: String,
  },
};
</script>