<template>
  <div class="container m-3 text-start">
    <!-- 中央大学 -->
    <h3 class="">中央大学</h3>
    <ul>
      <a
        href="https://www.chuo-u.ac.jp/academics/faculties/science/"
        target="_blank"
        rel="noopener"
      >
        <li class="text-primary my-3">理工学部/理工学専攻</li>
      </a>

      <a href="http://www.elect.chuo-u.ac.jp/" target="_blank" rel="noopener">
        <li class="text-primary my-3">
          電気電子情報通信工学科/電気電子情報通信工学専攻
        </li>
      </a>
    </ul>
    <br />
    <!-- 竹中工務店 -->
    <h3 class="">竹中工務店</h3>
    <ul>
      <a href="https://www.takenaka.co.jp/" target="_blank" rel="noopener">
        <li class="text-primary my-3">想いをかたちに未来へつなぐ 竹中工務店</li>
      </a>
      <a href="https://www.takenaka.co.jp/rd/" target="_blank" rel="noopener">
        <li class="text-primary my-3">竹中技術研究所</li>
      </a>
    </ul>
    <br />
    <!-- Jaxa -->
    <h3 class="">Jaxa</h3>
    <ul>
      <a href="https://www.jaxa.jp/" target="_blank" rel="noopener">
        <li class="text-primary my-3">Jaxa | 宇宙航空研究開発機構</li>
      </a>
    </ul>
  </div>
</template>