<template>
  <div id="app" class="position-relative">
    <GlobalHeader />
    <br />
    <div class="wrapper"><router-view></router-view></div>
    <br />
    <GlobalFooter />
  </div>
</template>

<script>
import GlobalHeader from "@/components/globals/GlobalHeader.vue";
import GlobalFooter from "@/components/globals/GlobalFooter.vue";

export default {
  name: "App",
  components: {
    GlobalHeader,
    GlobalFooter,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}
</style>
