import { createRouter, createWebHistory } from "vue-router";
//import gsap from "gsap";

import KlabHome from "@/views/KlabHome.vue";
import KlabMember from "@/views/KlabMember.vue";
import KlabLink from "@/views/KlabLink.vue";
import TopResearch from "@/views/TopResearch.vue";
import KlabGraduate from "@/views/KlabGraduate.vue";
import KlabHub from "@/views/KlabHub.vue";
import KlabRover from "@/views/KlabRover.vue";
import AllNews from "@/views/AllNews.vue";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: KlabHome,
    },
    {
      path: "/member",
      name: "member",
      component: KlabMember,
    },

    {
      path: "/link",
      name: "link",
      component: KlabLink,
    },
    {
      path: "/research",
      name: "research",
      component: TopResearch,
    },
    {
      path: "/member/graduate",
      name: "graduate",
      component: KlabGraduate,
    },
    {
      path: "/research/hub",
      name: "hub",
      component: KlabHub,
    },
    {
      path: "/research/rover",
      name: "rover",
      component: KlabRover,
    },
    {
      path: "/news",
      name: "news",
      component: AllNews,
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

//ページ遷移アニメーション
//router.beforeEach((_to, _from, next) => {
//const tl = gsap.timeline();
//tl.to(".wrapper", {
// duration: 0.4,
//opacity: 0,
//onComplete: () => {
//next();
//},
//}).to(".wrapper", {
//  duration: 0.,
//opacity: 1,
//});
//});
