import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";

//bootstrap-vueからBootstrapVueをインポート
import BootstrapVue3 from "bootstrap-vue-3";

// Bootstrap と BootstrapVueのCSSファイルをインポート
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

//MDB(Material Design for Bootstrap)のインポート
import "mdb-ui-kit/css/mdb.min.css";
import * as mdb from "mdb-ui-kit";

//MDB vueのインポート
//import 'mdb-vue-ui-kit/css/mdb.min.css';

//gsapのインポート
import gsap from "gsap";

const app = createApp(App);
app.use(router);
app.use(BootstrapVue3);
app.use(mdb);
app.use(gsap);
app.mount("#app");
