<template>
  <div class="container">
    <h2 class="text-start m-3 hub">
      <b>Rover</b>
    </h2>
    <!-- 研究概要 -->
    <div class="card">
      <div class="row">
        <div class="col-lg-8">
          <div class="card-body">
            <h5 class="card-title">研究概要</h5>
            <hr />
            <p class="card-text">
              &nbsp;
              &nbsp;近年、惑星表面や災害現場などの広大なフィールドには探査ローバのような大型の走行ロボットが投入されており、水資源などを探すことやサンプル回収をすることが求められています。一方で広大ではないフィールド、つまり街中やショッピングモールなど屋内屋外関係なく、人が多くいる場所で中型から小型のロボットが導入され、案内ロボットや荷物運搬作業の効率化といった省人化などが求められています。<br />
              &nbsp;
              &nbsp;しかし、単に屋内での荷物運搬用自律移動ロボットと言っても人が常に出入りし、周囲の環境が変化していく状況での走行は、単純な走行に膨大な計算を要し、人との衝突回避やロボットの姿勢が崩れる事による荷物の落下など多くの課題が存在します。<br />
              &nbsp;
              &nbsp;Rover班では、中型の自律移動ロボットを所有し、ビルや病院や商業施設といった屋内施設だけでなく、街中や空港といった屋外での自律走行を目指して日々開発・研究を行っています。<br />
              &nbsp;
              &nbsp;現在は、毎年夏に開催されるつくばチャレンジ出場を目標に基本性能だけでなく、新規機能の追加、各個人の研究成果の搭載先として活躍をしています。
            </p>
          </div>
        </div>
        <div class="col-lg-4 align-items-center d-flex">
          <div
            class="bg-image hover-overlay ripple"
            data-mdb-ripple-color="light"
          >
            <img
              src="@/assets/img/rover/rover_fig1.jpg"
              class="img-fluid rounded-9 p-3"
              width="400"
            />
            <a href="#!">
              <div
                class="mask"
                style="background-color: rgba(251, 251, 251, 0.15)"
              ></div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <br />
    <!-- ROSを用いた自立走行ロボットPF1 -->
    <div class="card">
      <div class="row">
        <div class="col-lg-8">
          <div class="card-body">
            <h5 class="card-title">ROSを用いた自立走行ロボットPF1</h5>
            <hr />
            <p class="card-text">
              &nbsp; &nbsp;
              Rover班のロボットはPF1と呼ばれ主にROSを用いて、自律走行を行っています。
              <br />
              &nbsp; &nbsp; ROSはRobot
              OperatingSystemと呼ばれるソフトウエア開発者のロボット・アプリケーション作成を支援するライブラリとツールを提供しているロボット向けオペレーティングシステムです。近年の自動車やロボットは単に「進む」、「止まる」、「曲がる」の動きだけでなく、Lidarやカメラを用いて周囲の環境を認識し、SLAMによって求められた自己位置からゴールに向かって走るといった複雑な処理で構成をされています。これら複雑な処理をNodeによる並列処理が可能なROSを用いる事で実現しており、PF1もシステムの80%がROSで構成されています。
            </p>
          </div>
        </div>
        <div class="col-lg-4 align-items-center d-flex">
          <div
            class="bg-image hover-overlay ripple"
            data-mdb-ripple-color="light"
          >
            <img
              src="@/assets/img/rover/rover_fig2.jpg"
              class="img-fluid rounded-9 p-3"
              width="400"
            />
            <a href="#!">
              <div
                class="mask"
                style="background-color: rgba(251, 251, 251, 0.15)"
              ></div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div class="row g-3">
      <hr />
      <h4>~本研究室での研究事例~</h4>
      <div class="col-lg-6">
        <!-- 電界ポテンシャル法を用いた軌道計画に関する研究 -->
        <div class="card">
          <div class="row">
            <div class="">
              <div class="card-body">
                <h5 class="card-title text-center">
                  ・電界ポテンシャル法を用いた軌道計画に関する研究
                </h5>
              </div>
            </div>
            <div class="align-items-center">
              <div
                class="bg-image hover-overlay ripple"
                data-mdb-ripple-color="light"
              >
                <img
                  src="@/assets/img/rover/rover_fig5.jpg"
                  class="img-fluid rounded-9 p-3"
                  width="400"
                />
                <a href="#!">
                  <div
                    class="mask"
                    style="background-color: rgba(251, 251, 251, 0.15)"
                  ></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <!--遠隔誘導のための軌道補正の導入及び軌道の追加・修正に関する研究 -->
        <div class="card h-100">
          <div class="row">
            <div class="">
              <div class="card-body">
                <h5 class="card-title text-center">
                  ・遠隔誘導のための軌道補正の導入及び軌道の追加・修正に関する研究
                </h5>
              </div>
            </div>
            <div class="align-items-center">
              <div
                class="bg-image hover-overlay ripple"
                data-mdb-ripple-color="light"
              >
                <img
                  src="@/assets/img/rover/rover_fig4.jpg"
                  class="img-fluid rounded-9 p-3"
                  width="400"
                />
                <a href="#!">
                  <div
                    class="mask"
                    style="background-color: rgba(251, 251, 251, 0.15)"
                  ></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <!--深層学習を用いた移動ロボットによる特定人物の安定追跡機能に関する研究 -->
        <div class="card h-100">
          <div class="row">
            <div class="">
              <div class="card-body">
                <h5 class="card-title text-center">
                  ・深層学習を用いた移動ロボットによる特定人物の安定追跡機能に関する研究
                </h5>
              </div>
            </div>
            <div class="align-items-center">
              <div
                class="bg-image hover-overlay ripple"
                data-mdb-ripple-color="light"
              >
                <img
                  src="@/assets/img/rover/rover_fig3.jpg"
                  class="img-fluid rounded-9 p-3"
                  width="400"
                />
                <a href="#!">
                  <div
                    class="mask"
                    style="background-color: rgba(251, 251, 251, 0.15)"
                  ></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <!-- ホイールベース可変懸架機構における段差乗り上げ時の力学的解析と効果検証に関する研究 -->
        <div class="card">
          <div class="row">
            <div class="">
              <div class="card-body">
                <h5 class="card-title text-center">
                  ・ホイールベース可変懸架機構における段差乗り上げ時の力学的解析と効果検証に関する研究
                </h5>
              </div>
            </div>
            <div class="align-items-center">
              <div
                class="bg-image hover-overlay ripple"
                data-mdb-ripple-color="light"
              >
                <img
                  src="@/assets/img/rover/rover_fig7.jpg"
                  class="img-fluid rounded-9 p-3"
                  width="400"
                />
                <a href="#!">
                  <div
                    class="mask"
                    style="background-color: rgba(251, 251, 251, 0.15)"
                  ></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <!-- ホイールベース可変懸架機構における段差乗り上げ時の力学的解析と効果検証に関する研究 -->
        <div class="card">
          <div class="row">
            <div class="">
              <div class="card-body">
                <h5 class="card-title text-center">
                  ・ROSを利用した自律移動ロボットの機能構築と機能統合に関する研究
                </h5>
              </div>
            </div>
            <div class="align-items-center">
              <div
                class="bg-image hover-overlay ripple"
                data-mdb-ripple-color="light"
              >
                <img
                  src="@/assets/img/rover/rover_fig8.jpg"
                  class="img-fluid rounded-9 p-3"
                  width="200"
                />
                <a href="#!">
                  <div
                    class="mask"
                    style="background-color: rgba(251, 251, 251, 0.15)"
                  ></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <h4>研究紹介動画</h4>
      <div class="row g-3">
        <div class="col-lg-6">
          <video
            controls
            muted
            loop
            width="300"
            src="@/assets/video/rover/rover_video1.mp4"
          ></video>
        </div>

        <div class="col-lg-6">
          <video
            controls
            width="300"
            height=""
            src="@/assets/video/rover/rover_video2.mp4"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h4 {
  text-align: start;
  font-size: 1.5em;
  margin: 1rem;
}
p,
h5,
ul {
  text-align: start;
}
</style>
