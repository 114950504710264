<template>
  <div class="container">
    <img
      src="@/assets/img/rover/rover_1.jpg"
      class="rounded-5 d-block w-100"
      alt=" 國井研究室ホーム画像"
    />
    <br />
  </div>
</template>

<style></style>
