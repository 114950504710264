<template>
  <div class="container">
    <h3 class="text-start p-3">研究紹介動画</h3>
    <div class="youtube">
      <div class="inner">
        <iframe
          class="rounded-9"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/sKuak7KwV2s"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <!-- .inner -->
      </div>
      <!-- .youtube -->
    </div>
  </div>
</template>

<style scoped>
.youtube {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}

.youtube .inner {
  padding-top: 56.25%;
  position: relative;
  height: 0;
  overflow: hidden;
}

.youtube .inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
